const React = require('react');
const stripe = Stripe(STRIPE_PUBLIC_KEY);
const Avatar = require('@mui/material/Avatar').default;
const Button = require('@mui/material/Button').default;
const CssBaseline = require('@mui/material/CssBaseline').default;
const TextField = require('@mui/material/TextField').default;
const FormControlLabel = require('@mui/material/FormControlLabel').default;
const Checkbox = require('@mui/material/Checkbox').default;
const Link = require('@mui/material/Link').default;
const Grid = require('@mui/material/Grid').default;
const Box = require('@mui/material/Box').default;
const LockOutlinedIcon = require('@mui/icons-material/LockOutlined').default;
const Copyright = require('./Copyright');
const Typography = require('@mui/material/Typography').default;
const Container = require('@mui/material/Container').default;
const Image = require('mui-image').default;
const StripeActions = require('../actions/StripeActions');
const NasEmailEventsActions = require('../actions/NasEmailEventsActions');
const GuestsActions = require('../actions/GuestsActions');
const queryString = require('query-string').default;
const { getPersonaName } = require('../helpers/GeneralHelpers');
const { EMAIL } = require('../server/config');
const PrivacyPolicy = require('./PrivacyPolicy');

class PaymentLandingPage extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
      <Container className="payment-landing-page" component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Image className="top-image" src="spring-haven-motel.svg" alt="Spring Haven Motel" />
          <Typography variant="body1">
            67 Spring Haven Rd, Mitchell, IN 47446
          </Typography>
          <Typography variant="body1">
            Please call us at (812) 849-5905 to reserve a room.
          </Typography>
        </Box>
        <Copyright sx={{ mt: 8 }} />
        <PrivacyPolicy />
      </Container>
    );
  }
}

module.exports = PaymentLandingPage;