'use strict'
const React = require('react');
const Typography = require('@mui/material/Typography').default;
const Link = require('@mui/material/Link').default;

class Copyright extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...this.props}>
        {'Copyright © '}
        <span color="inherit">
          Spring Haven Hospitality LLC
        </span>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
}

module.exports = Copyright;