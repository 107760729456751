const { Typography } = require('@mui/material');
const React = require('react');
const Modal = require('@mui/material/Modal').default;
const Box = require('@mui/material/Box').default;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'xs',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius:4,
  minWidth:365
};

class PaymentLandingPage extends React.Component {
  constructor(props){
    super(props)
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      showModal: false
    }
  }

  toggleModal(){
    this.setState({
      showModal: !this.state.showModal
    });
  }

  render(){
    console.log('this.state', this.state);
    return (
      <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
      >
        <Typography onClick={this.toggleModal} variant="body2" sx={{cursor: "pointer", textDecoration: "underline"}} color="text.secondary">Privacy Policy</Typography>
        { this.state.showModal && 
          <Modal open={this.state.showModal} onClose={this.toggleModal}>
            <Box sx={style}>
                <Typography variant="body1" sx={{fontWeight: "bold", marginBottom: "15px"}}>Messaging Terms & Conditions</Typography>
                <Typography variant="body1">You agree to receive informational messages (appointment reminders, account notifications, etc.) from Spring Haven Motel. Message frequency varies. Message and data rates may apply. For help, reply HELP or email us at manager@springhavenmotel.com. You can opt out at any time by replying STOP.</Typography>
                <Typography variant="body1">No mobile information will be shared with any third parties/affiliates for marketing/promotional purposes. All other categories exclude text messaging originator opt-in data and consent; this information will not be shared with or obtained by any third parties. If you wish to be removed from receiving future communications, you can opt out at any time by texting STOP or contacting us at manager@springhavenmotel.com.</Typography>
            </Box>
            
          </Modal>
        }
      </Box>
    );
  }
}

module.exports = PaymentLandingPage;